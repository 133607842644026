<template>
  <div>
    <h1 class="font-zeyada text-4xl my-4">Recetas</h1>

    <div
      class="
        w-12
        h-12
        mx-auto
        my-10
        border-2 border-yellow-500
        rounded-full
        loader
      "
      v-if="!isLoaded"
    ></div>

    <TransitionRoot
      :show="isLoaded"
      enter="transition-opacity duration-500"
      enter-from="opacity-0"
      enter-to="opacity-100"
    >
      <ul
        class="
          space-y-12
          sm:grid sm:grid-cols-2
          sm:gap-x-6 sm:gap-y-12
          sm:space-y-0
          lg:grid-cols-3
          lg:gap-x-8
        "
        v-if="isLoaded"
      >
        <li v-for="recipe in recipes" :key="recipe.slug">
          <router-link :to="{ name: 'Receta', params: { slug: recipe.slug } }">
            <div class="space-y-4">
              <div class="aspect-w-4 aspect-h-3">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  :src="API_BASE_URL + recipe.foto.url"
                  :alt="recipe.titulo"
                />
              </div>

              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h3 class="text-center text-gray-600 text-sm">
                    {{ recipe.titulo }}
                  </h3>
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref } from "vue";
import { TransitionRoot } from "@headlessui/vue";
import { API_BASE_URL } from "@/config/parameters";

export default {
  name: "recipes",
  components: { TransitionRoot },
  setup() {
    const recipes = ref([]);
    const isLoaded = ref(false);

    fetch(
      `${API_BASE_URL}/recetas?_limit=1000&_start=0&_sort=updated_at:DESC&_publicationState=live`
    )
      .then((response) => response.json())
      .then((data) => {
        recipes.value.push(...data);
        isLoaded.value = true;
      })
      .catch((error) => console.error(error));

    return {
      API_BASE_URL,
      recipes,
      isLoaded,
    };
  },
};
</script>
